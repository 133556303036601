import React from 'react';
import Fade from 'react-reveal/Fade';
import {
  SectionImageContentComponent,
  SectionTextContent,
  SectionImage,
  SectionImageType,
} from '../shared';
import { MainContent } from '../layout/main-content';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { siteData } from '../../data/site-data';

export const HomeSolutions = () => {
  /**
   * Hooks
   */
  const breakpoints = useBreakpoint();

  /**
   * DOM
   */
  const isMobile = breakpoints.xs || breakpoints.sm || breakpoints.md ? true : false;
  return (
    <div className="bg-accent-light py-6">
      <MainContent>
        {siteData.homePage.sectionSolutions.map((item, index) => {
          return (
            <Fade>
              <SectionImageContentComponent
                direction={index % 2 === 0 || isMobile ? 'ltr' : 'rtl'}
                cls="py-6 md:py-12"
                fgImage={
                  <SectionImage
                    name={item.imageName as SectionImageType}
                    alt={item.title}
                  />
                }
                content={
                  <SectionTextContent
                    cls="items-center lg:mb-12"
                    title={item.title}
                    titleCls={
                      'ff-secondary text-center md:text-left text-primary typo-h3'
                    }
                    description={item.description}
                    descriptionCls={'text-grey-darker text-center md:text-left typo-b2'}
                  />
                }
              />
            </Fade>
          );
        })}
      </MainContent>
    </div>
  );
};
