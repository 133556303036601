import React, { FC } from 'react';
import { siteData } from '../../data/site-data';
import { SectionCard } from '../shared/section-card';

export const HomeTestimonials: FC = () => {
  /**
   * DOM
   */
  return (
    <SectionCard
      title={siteData.homePage.sectionTestimonial.message}
      subTitle={siteData.homePage.sectionTestimonial.author}
    />
  );
};
