import React, { useEffect, useState } from 'react';
import { PageLayout } from '../components/layout';
import { useLocation } from '@reach/router';
import {
  HomeHero,
  HomeSolutions,
  HomeTestimonials,
  HomeTradeCommissionFree,
  HomeContactUs,
} from '../components/home';
import useIsClient from '../hooks/use-is-client';

const Home = () => {
  /**
   * State
   */
  const [prompt, setPrompt] = useState(0);

  /**
   * Hooks
   */
  const { isClient } = useIsClient();
  const location = useLocation();
  useEffect(() => {
    if (!isClient) {
      return;
    }
    const contactUsElement = 'get-started';
    const hash = (location.hash || '').replace('#', '');
    if (!hash) {
      window.scrollTo(0, 0);
      return;
    }
    if (hash !== contactUsElement) {
      return;
    }
    const top = document.getElementById(contactUsElement).offsetTop;
    window.scrollTo(0, top);
  }, [isClient, location]);

  /**
   * DOM
   */
  return (
    <PageLayout showMobileAppPrompt={prompt}>
      <HomeHero />
      <HomeSolutions />
      <HomeTestimonials />
      <HomeTradeCommissionFree
        onShowMobileAppPrompt={() => {
          setPrompt(prompt + 1);
        }}
      />
      <div>
        <div id="get-started"></div>
        {/* keeping above id seperate means, it jumps to correct location */}
        {isClient && <HomeContactUs />}
      </div>
    </PageLayout>
  );
};

export default Home;
