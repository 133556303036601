import React, { FC } from 'react';
import { SectionTextContent } from '../shared';
import { CTA_DOWNLOAD_BUTTON_TEXT } from '../../constants';
import { SectionImageContentComponent } from '../shared/section-image-content';
import { isMobile as isMobileDevice } from 'react-device-detect';
import { useMobileAppUrl } from '../../hooks/use-mobile-app-url';
import { siteData } from '../../data/site-data';
import { SectionImage } from '../shared/section-image';

interface Props {
  onShowMobileAppPrompt: (value: boolean) => void;
}

export const HomeTradeCommissionFree: FC<Props> = ({ onShowMobileAppPrompt }) => {
  /**
   * Hooks
   */
  const mobileAppUrl = useMobileAppUrl();

  /**
   * DOM
   */
  return (
    <section className="bg-accent-light md:py-20 pb-32">
      <SectionImageContentComponent
        cls="pt-6 pb-4 md:py-12 lg:mx-auto px-10  md:px-14 max-w-screen-xl w-full"
        fgImage={
          <SectionImage
            name="home-section-trade-commission-free"
            alt="Stablehouse mobile app"
            size="big"
          />
        }
        fgImageMobile={
          <SectionImage
            name="home-section-trade-commission-free"
            alt="Stablehouse mobile app"
            size="small"
          />
        }
        content={
          <SectionTextContent
            title={'Trade commission-free everywhere'}
            titleCls={'ff-secondary text-center md:text-left text-primary typo-h3'}
            description={siteData.homePage.sectionTradeCommisionFree.description}
            descriptionCls={'text-grey-darker text-center md:text-left typo-b2'}
          >
            <div className="flex flex-row justify-center md:justify-start w-full">
              <a
                target="_blank"
                href={mobileAppUrl}
                className="app-button-primary no-underline"
                onClick={e => {
                  if (!isMobileDevice) {
                    e.preventDefault();
                    onShowMobileAppPrompt(true);
                  }
                }}
              >
                {CTA_DOWNLOAD_BUTTON_TEXT}
              </a>
            </div>
          </SectionTextContent>
        }
      />
    </section>
  );
};
