import React, { FC, useEffect, useState } from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { BaseForm } from '../../definitions/base-form';
import * as Yup from 'yup';
import { nameof } from '../../utils/typescript-helpers';
import { clearFormErrors } from '../../utils/handle-errors';
import {
  useRecaptcha,
  sendApiV1ContactNew,
  AppIcon,
  AppValidationWrapper,
  TextInput,
  SendContactFormNew,
  SendContactFormInterests,
  DEFAULTS,
  ERROR_MESSAGES,
} from 'common';
import { ContactUsThankYou } from '../shared';
import { MainContent } from '../layout/main-content';
import { EXTERNAL_ROUTES } from '../../routes';
import { Link } from 'gatsby';

type Form = SendContactFormNew & BaseForm;

const interestsMap: Record<SendContactFormInterests, string> = {
  [SendContactFormInterests.Trading]: 'Trading',
  [SendContactFormInterests.Custody]: 'Custody',
  [SendContactFormInterests.Staking]: 'Staking',
  [SendContactFormInterests.WhiteLabel]: 'White-label',
  [SendContactFormInterests.Investments]: 'Investments',
  [SendContactFormInterests.Other]: 'Other',
};

const containsUnallowedChars = (value = '') => {
  const chars = ['`', '^', '!', '#', '$', '%', '*', '=', '<', '>', ';', '{', '}'];
  if (
    chars.some(function (v) {
      return value.indexOf(v) >= 0;
    })
  ) {
    return true;
  }
  return false;
};

const schema = Yup.object().shape({
  [nameof<Form>('firstName')]: Yup.string().nullable(),
  // .min(DEFAULTS.CHAR_LENGTH_2, ERROR_MESSAGES.MIN_2_CHARACTERS)
  // .max(DEFAULTS.CHAR_LENGTH_255, ERROR_MESSAGES.MAX_255_CHARACTERS)
  // .test(
  //   'check-unallowed-chars',
  //   'Given value is invalid',
  //   value => !containsUnallowedChars(value)
  // )
  [nameof<Form>('lastName')]: Yup.string().nullable(),
  // .min(DEFAULTS.CHAR_LENGTH_2, ERROR_MESSAGES.MIN_2_CHARACTERS)
  // .max(DEFAULTS.CHAR_LENGTH_255, ERROR_MESSAGES.MAX_255_CHARACTERS)
  // .test(
  //   'check-unallowed-chars',
  //   'Given value is invalid',
  //   value => !containsUnallowedChars(value)
  // ),
  [nameof<Form>('email')]: Yup.string()
    .email(ERROR_MESSAGES.INVALID_EMAIL)
    .required(ERROR_MESSAGES.REQUIRED_VALUE)
    .max(DEFAULTS.CHAR_LENGTH_255, ERROR_MESSAGES.MAX_255_CHARACTERS),
  [nameof<Form>('interests')]: Yup.string().nullable(),
  // .required(ERROR_MESSAGES.REQUIRED_VALUE),
  [nameof<Form>('howDidYouHearAboutUs')]: Yup.string().nullable(),
  // .min(DEFAULTS.CHAR_LENGTH_2, ERROR_MESSAGES.MIN_2_CHARACTERS)
  // .max(DEFAULTS.CHAR_LENGTH_500, ERROR_MESSAGES.MAX_500_CHARACTERS),
});

interface ContactUsFormProps {
  onSent: (value: boolean) => void;
}

const ContactUsForm: FC<ContactUsFormProps> = ({ onSent }) => {
  /**
   * State
   */
  const [error, setError] = useState<string | null>(null);
  /**
   * Hooks
   */
  const { getRecaptchaToken } = useRecaptcha({
    key:
      // @ts-ignore
      typeof window === 'undefined' || (typeof window !== 'undefined' && window.Cypress)
        ? process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY_CYPRESS || ''
        : process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY || '',
  });

  /**
   * Methods
   */
  const onFormSubmit = async (values: Form, helper: FormikHelpers<Form>) => {
    clearFormErrors(helper);
    helper.setSubmitting(true);
    setError(null);

    try {
      const recaptchaToken = await getRecaptchaToken();
      const body: SendContactFormNew = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: null,
        company: 'cannot be empty',
        country: null,
        interests: values.interests,
        howDidYouHearAboutUs: values.howDidYouHearAboutUs,
        recaptchaToken,
      };
      const { isSuccessful, errorMessage } = await sendApiV1ContactNew(
        EXTERNAL_ROUTES.API_URL,
        body
      );
      if (isSuccessful) {
        onSent(true);
      } else {
        setError(errorMessage || ERROR_MESSAGES.CONTACT_US_FAILED);
      }
    } catch (error) {
      setError(error || ERROR_MESSAGES.CONTACT_US_FAILED);
    }
    helper.setSubmitting(false);
  };

  /**
   * DOM
   */
  const initialValues: SendContactFormNew = {
    firstName: ``,
    lastName: ``,
    email: ``,
    phoneNumber: null,
    country: null,
    company: 'cannot be empty',
    interests: SendContactFormInterests.Trading,
    howDidYouHearAboutUs: ``,
    recaptchaToken: '',
  };

  return (
    <MainContent cls="grid grid-cols-5 md:grid-cols-14 gap-x-3 p-8 md:my-0 items-center z-10 type-body-copy">
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onFormSubmit}
        enableReinitialize
      >
        {({ errors, touched, handleChange, handleBlur, values, isSubmitting }) => (
          <Form className="col-start-1 col-span-full md:col-start-5 md:col-span-6">
            <div className="flex flex-col items-center relative">
              {/* error  */}
              {error && error.length > 0 && (
                <div className="px-4 py-2 text-failure bg-white rounded-md border pointer-events-none mb-6">
                  {error}
                </div>
              )}
            </div>
            {/* form  */}
            <div>
              {/* row 1  */}
              <div className="flex flex-col lg:flex-row">
                {/* firstname  */}
                <label className="block w-full mb-4">
                  <p className="text-grey-darker typo-b2 mb-3">First name</p>
                  <AppValidationWrapper
                    show={!!errors.firstName && !!touched.firstName}
                    message={errors.firstName}
                    testid="firstName"
                  >
                    <TextInput
                      type="text"
                      id="firstName"
                      name={nameof<Form>('firstName')}
                      value={values.firstName}
                      errored={!!errors.firstName && !!touched.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </AppValidationWrapper>
                </label>

                {/* lastname  */}
                <label className="block w-full mb-4 ml-0 lg:ml-4">
                  <p className="text-grey-darker typo-b2 mb-3">Last name</p>
                  <AppValidationWrapper
                    show={!!errors.lastName && !!touched.lastName}
                    message={errors.lastName}
                    testid="lastName"
                  >
                    <TextInput
                      type="text"
                      id="lastname"
                      name={nameof<Form>('lastName')}
                      value={values.lastName}
                      errored={!!errors.lastName && !!touched.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </AppValidationWrapper>
                </label>
              </div>

              {/* email  */}
              <label className="block mb-4">
                <p className="text-grey-darker typo-b2  my-3">Email*</p>
                <AppValidationWrapper
                  show={!!errors.email && !!touched.email}
                  message={errors.email}
                  testid="email"
                >
                  <TextInput
                    type="text"
                    id="email"
                    name={nameof<Form>('email')}
                    value={values.email}
                    errored={!!errors.email && !!touched.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </AppValidationWrapper>
              </label>

              {/* interests  */}
              <label className="block mb-4">
                <p className="text-grey-darker typo-b2 my-3">Service of interest?</p>
                <AppValidationWrapper
                  show={!!errors.interests && !!touched.interests}
                  message={errors.interests}
                  testid="interests"
                >
                  <Field
                    as="select"
                    name={nameof<Form>('interests')}
                    data-testid="interests-select"
                    className="app-input"
                  >
                    <option value="" className="text-gray-400 text-xl">
                      Please select
                    </option>
                    {Object.entries(interestsMap).map(([k, v]) => (
                      <option key={k} className="text-xl" value={k}>
                        {v}
                      </option>
                    ))}
                  </Field>
                </AppValidationWrapper>
              </label>

              {/* message  */}
              <label className="block w-full mb-4">
                <p className="text-grey-darker typo-b2 my-3">Referred by</p>
                <AppValidationWrapper
                  show={!!errors.howDidYouHearAboutUs && !!touched.howDidYouHearAboutUs}
                  message={errors.howDidYouHearAboutUs}
                  testid="howDidYouHearAboutUs"
                >
                  <TextInput
                    type="text"
                    id="howDidYouHearAboutUs"
                    name={nameof<Form>('howDidYouHearAboutUs')}
                    value={values.howDidYouHearAboutUs}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </AppValidationWrapper>
              </label>

              {/* actions  */}
              <div className="mt-6">
                <div className="w-full text-sm text-sh-gray-dark my-4">
                  *Required Fields
                </div>
                <button
                  style={{ width: '100%' }}
                  type="submit"
                  className="app-button-accent"
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </MainContent>
  );
};

export const HomeContactUs: FC = () => {
  /**
   * State
   */

  const [showSent, setShowSent] = useState<boolean>(false);

  /**
   * Hooks
   */
  useEffect(() => setShowSent(false), []);

  /**
   * DOM
   */
  return (
    <section className="bg-white py-12">
      <div className="md:container md:mx-auto px-10 w-full md:px-0 flex flex-col items-center">
        <AppIcon icon="email-checked-light" size={64} />
        <h2 className="ff-secondary mt-4 text-center">Get started</h2>
        <p className="text-grey-darker w-full md:w-6/12 text-center text-normal mt-6">
          Please fill out the form below or send an email at{' '}
          <a href="mailto:onboarding@stablehouse.com">onboarding@stablehouse.com</a> to
          start the process of opening an account. Please note we are currently onboarding only non-US institutions and <a className="underline" href="https://help.stablehouse.com/hc/en-us/articles/12002168894610-What-is-a-Qualified-Customer-">qualified customers</a>.
        </p>
      </div>
      {showSent && <ContactUsThankYou onAcknowledge={() => setShowSent(false)} />}
      {!showSent && <ContactUsForm onSent={value => setShowSent(value)} />}
    </section>
  );
};
