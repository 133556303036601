import { useUrls } from 'common';
import { useEffect, useState } from 'react';
import { useSiteUrls } from './use-site-urls';
import { isMobile as isMobileDevice } from 'react-device-detect';

export const useMobileAppUrl = () => {
  /**
   * State
   */
  const [toUrl, setToUrl] = useState<string | null>(null);

  /**
   * Methods
   */
  const getUrl = (iosAppUrl: string, androidAppUrl: string, fallbackUrl: string) => {
    if (!isMobileDevice) {
      return fallbackUrl;
    }
    const isIOSDevice = () => {
      return (
        [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod',
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes('Mac OS') && 'ontouchend' in document) // iPad on iOS 13 detection
      );
    };
    const isAppleDevice = isIOSDevice();
    if (!isAppleDevice) {
      return androidAppUrl;
    }
    return iosAppUrl;
  };

  /**
   * Hooks
   */
  const { appUrl, siteUrl } = useSiteUrls();
  const { mobileAppUrl } = useUrls(siteUrl, appUrl);
  useEffect(() => {
    if (typeof window === 'undefined' || !mobileAppUrl) {
      return;
    }
    const value = getUrl(mobileAppUrl.ios, mobileAppUrl.android, mobileAppUrl.download);
    setToUrl(value);
  }, [mobileAppUrl]);

  /**
   * Return
   */
  return toUrl;
};
